var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Layout", [
    _c("div", { staticClass: "row justify-content-center" }, [
      _c("div", { staticClass: "col-md-8 col-lg-6 col-xl-5" }, [
        _c("div", { staticClass: "card overflow-hidden" }, [
          _c("div", { staticClass: "bg-gradient-primary" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-7" }, [
                _c("div", { staticClass: "text-white p-4" }, [
                  _c("h5", { staticClass: "text-white" }, [
                    _vm._v(_vm._s(_vm.$t("login.welcome.title"))),
                  ]),
                  _c("p", [
                    _vm._v(_vm._s(_vm.$t("login.welcome.description"))),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "col-5 align-self-center" }, [
                _c("img", {
                  staticClass: "img-fluid",
                  attrs: {
                    src: require("@/assets/images/login-image.png"),
                    alt: "",
                  },
                }),
              ]),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "card-body pt-0" },
            [
              _c("div", [
                _c("div", { staticClass: "avatar-md profile-user-wid mb-4" }, [
                  _c(
                    "span",
                    { staticClass: "avatar-title rounded-circle bg-light" },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/Redem Icon.png"),
                          alt: "",
                          height: "38",
                        },
                      }),
                    ]
                  ),
                ]),
              ]),
              _c(
                "b-alert",
                {
                  staticClass: "mt-3",
                  attrs: { variant: "success", dismissible: "" },
                  model: {
                    value: _vm.loginSuccess,
                    callback: function ($$v) {
                      _vm.loginSuccess = $$v
                    },
                    expression: "loginSuccess",
                  },
                },
                [_vm._v(_vm._s(_vm.$t("login.alert.success")))]
              ),
              _c(
                "b-alert",
                {
                  staticClass: "mt-3",
                  attrs: { variant: "danger", dismissible: "" },
                  model: {
                    value: _vm.isLoginError,
                    callback: function ($$v) {
                      _vm.isLoginError = $$v
                    },
                    expression: "isLoginError",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.logError) + " ")]
              ),
              _c(
                "b-alert",
                {
                  staticClass: "mb-3 m-2",
                  attrs: {
                    show: _vm.dismissCountDownSuccess,
                    variant: "success",
                    dismissible: "",
                  },
                  on: {
                    dismissed: function ($event) {
                      _vm.dismissCountDownSuccess = 0
                    },
                    "dismiss-count-down": _vm.countDownChangedSuccess,
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("login.alert.activation")) + " ")]
              ),
              _c(
                "b-form",
                {
                  staticClass: "p-2",
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.clickLoginSubmit.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "input-group-1",
                        label: this.$t("login.form.email.label"),
                        "label-for": "input-1",
                      },
                    },
                    [
                      _c("b-form-input", {
                        class: {
                          "is-invalid": _vm.submit && _vm.$v.email.$error,
                        },
                        attrs: {
                          id: "input-1",
                          type: "text",
                          placeholder: this.$t("login.form.email.placeholder"),
                        },
                        model: {
                          value: _vm.email,
                          callback: function ($$v) {
                            _vm.email = $$v
                          },
                          expression: "email",
                        },
                      }),
                      _vm.submit && _vm.$v.email.$error
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            !_vm.$v.email.required
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "login.form.email.requiredErrorMsg"
                                      )
                                    )
                                  ),
                                ])
                              : _vm._e(),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "input-group-2",
                        label: this.$t("login.form.password.label"),
                        "label-for": "input-2",
                      },
                    },
                    [
                      _c("b-form-input", {
                        class: {
                          "is-invalid": _vm.submit && _vm.$v.password.$error,
                        },
                        attrs: {
                          id: "input-2",
                          type: "password",
                          placeholder: this.$t(
                            "login.form.password.placeholder"
                          ),
                        },
                        model: {
                          value: _vm.password,
                          callback: function ($$v) {
                            _vm.password = $$v
                          },
                          expression: "password",
                        },
                      }),
                      _vm.submit && _vm.$v.password.$error
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            !_vm.$v.password.required
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "login.form.password.requiredErrorMsg"
                                      )
                                    )
                                  ),
                                ])
                              : _vm._e(),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mt-3" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "btn-block",
                          attrs: { type: "submit", variant: "success" },
                        },
                        [_vm._v(_vm._s(_vm.$t("login.form.loginButton")))]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mt-4 text-center" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "text-muted",
                          attrs: { tag: "a", to: "/forgot-password" },
                        },
                        [
                          _c("i", { staticClass: "mdi mdi-lock mr-1" }),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("login.form.forgetPasswordButton")
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "mt-5 text-center" }, [
          _c("p", [
            _vm._v(
              " © " +
                _vm._s(new Date().getFullYear()) +
                " Redem GmbH | made with "
            ),
            _c("i", { staticClass: "mdi mdi-heart text-danger" }),
            _vm._v(" in Austria "),
          ]),
        ]),
        _c("div", { staticClass: "mt-3 text-center" }, [
          _c(
            "span",
            {
              staticClass: "mr-2",
              staticStyle: { cursor: "pointer" },
              on: {
                click: function ($event) {
                  return _vm.setLanguage(_vm.languages[0].language)
                },
              },
            },
            [
              _c("img", {
                staticClass: "mr-1",
                attrs: { src: _vm.languages[0].flag, height: "10" },
              }),
              _vm._v(" " + _vm._s(_vm.languages[0].title) + " "),
            ]
          ),
          _vm._v(" • "),
          _c(
            "span",
            {
              staticClass: "ml-2",
              staticStyle: { cursor: "pointer" },
              on: {
                click: function ($event) {
                  return _vm.setLanguage(_vm.languages[1].language)
                },
              },
            },
            [
              _c("img", {
                staticClass: "mr-1",
                attrs: { src: _vm.languages[1].flag, height: "10" },
              }),
              _vm._v(" " + _vm._s(_vm.languages[1].title) + " "),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }